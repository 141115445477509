import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import './scss/style.scss'
import ToastContainerComponent from './components/toast/ToastContainerComponent'
import { GuarantorRoutes, OperatorsRoutes, defaultRoutes } from './routes/routes'
import { useAuth } from 'src/hooks/auth/useAuth'

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const selectRole = (userId) => {
  switch (userId) {
    case 10: {
      return GuarantorRoutes
    }
    case 7: {
      return OperatorsRoutes
    }
    default: {
      return defaultRoutes
    }
  }
}

const App = () => {
  const { token, setToken } = useAuth()
  const routesResultData = selectRole(token?.userType)
  const routesResult = useRoutes(routesResultData)

  return (
    <>
      <Suspense fallback={<Loading />}>{routesResult}</Suspense>
      <ToastContainerComponent />
    </>
  )
}

export default App
