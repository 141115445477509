import React from 'react'
import { Navigate } from 'react-router-dom'
import { ProtectedRoute } from '../components/protected-route/ProtectedRoute'

const GuarantorDashboard = React.lazy(() => import('src/views/dashboard/GuarantorDashboard'))
const OperatorDashboard = React.lazy(() => import('src/views/dashboard/OperatorDashboard'))
const Profile = React.lazy(() => import('src/views/profile/Profile'))
const EditProfile = React.lazy(() => import('src/views/profile/EditProfile'))
const ChangePassword = React.lazy(() => import('src/views/profile/ChangePassword'))
const TransacReports = React.lazy(() => import('src/views/reports/TransactionOperatorReports'))
const TransacGuarantorReports = React.lazy(() =>
  import('src/views/reports/TransactionGuarantorReport'),
)

const KioskTellerReports = React.lazy(() =>
  import('src/views/downlinereports/kioskreports/KioskTellerReports'),
)
const DownlineQrPlayerReports = React.lazy(() =>
  import('src/views/downlinereports/kioskreports/DownlineQRPlayerReports'),
)
const KioskCashierReports = React.lazy(() =>
  import('src/views/downlinereports/kioskreports/DownlineCashierClaimed'),
)
const VIPReports = React.lazy(() => import('src/views/downlinereports/VIPreports/VipReportsList'))
const OperatorsListReports = React.lazy(() =>
  import('src/views/downlinereports/operatorreports/OperatorsListReports'),
)
const KioskStaffList = React.lazy(() =>
  import('src/views/downlinereports/kioskreports/KioskStaffList'),
)
const ActivityReportsOperatorList = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityReportsOperatorList'),
)
const ActivityLogTabs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogTabs'),
)
const ActivityLogs = React.lazy(() =>
  import('src/views/downlinereports/activityreports/ActivityLogs'),
)
const EventsReports = React.lazy(() => import('src/views/eventreports/guarantor/EventsReportList'))
const OperatorsPerEventReport = React.lazy(() =>
  import('src/views/eventreports/guarantor/OperatorsPerEventReport'),
)
const OperatorEventsReportList = React.lazy(() =>
  import('src/views/eventreports/operator/OperatorEventsReportList'),
)
const DownlinePerEventReport = React.lazy(() =>
  import('src/views/eventreports/operator/DownlinePerEventReport'),
)
const OperatorReportTabs = React.lazy(() =>
  import('src/views/downlinereports/reports/OperatorTabs'),
)
const OperatorReportTabsQR = React.lazy(() =>
  import('src/views/downlinereports/reports/OperatorTabsQR'),
)
const GuarantorReportTabs = React.lazy(() =>
  import('src/views/downlinereports/reports/GuarantorTabs'),
)
const MyRequestDetails = React.lazy(() => import('src/views/reports/WalletReports'))
const Request = React.lazy(() => import('src/views/request/Request'))
const MyRequest = React.lazy(() => import('src/views/request/MyRequest'))
const RequestDownline = React.lazy(() => import('src/views/request/RequestDownline'))
const RequestApproval = React.lazy(() => import('src/views/request/RequestApproval'))
const GuarantorCommissionManagement = React.lazy(() =>
  import('src/views/commission/GuarantorCommissionManagement'),
)
const OperatorCommissionManagement = React.lazy(() =>
  import('src/views/commission/OperatorCommisionManagement'),
)

//pages
const MyOperators = React.lazy(() => import('src/views/mydownlines/MyOperators'))
const SubOperators = React.lazy(() => import('src/views/mydownlines/SubOperators'))
const Players = React.lazy(() => import('src/views/mydownlines/Players'))
const Staffs = React.lazy(() => import('src/views/mydownlines/Staff'))
const QRPlayers = React.lazy(() => import('src/views/mydownlines/QRPlayer'))
const OwnedStaffs = React.lazy(() => import('src/views/mydownlines/OperatorStaffs'))
const Login = React.lazy(() => import('src/views/login/Login'))
const AuthLayout = React.lazy(() => import('src/components/layout/AuthLayout'))
const MainLayout = React.lazy(() => import('src/components/layout/MainLayout'))

export const GuarantorRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <GuarantorDashboard /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      { path: '/my-operators', name: 'Downlines Management', element: <MyOperators /> },
      // { path: '/sub-operators', name: 'Downlines Management', element: <SubOperators /> },
      { path: '/reports', name: 'Transaction Reports', element: <TransacGuarantorReports /> },
      { path: '/operators-list', name: 'Operator List', element: <OperatorsListReports /> },
      {
        path: '/activity-reports',
        name: 'Activity User List',
        element: <ActivityReportsOperatorList />,
      },
      { path: '/activitylogs', name: 'Activity Reports', element: <ActivityLogs /> },
      {
        path: '/operators-per-event-commission',
        name: 'Events Reports',
        element: <OperatorsPerEventReport />,
      },

      {
        path: '/operator-reports',
        name: 'Transaction Reports',
        element: <GuarantorReportTabs />,
      },
      {
        path: '/manage-commission',
        name: 'Manage Commission',
        element: <GuarantorCommissionManagement />,
      },
      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/owned-staff', name: 'Owned Staff', element: <OwnedStaffs /> },
      { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const OperatorsRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <AuthLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '/', exact: true, name: 'Home' },
      { path: '/dashboard', name: 'Dashboard', element: <OperatorDashboard /> },
      { path: '/profile', name: 'Profile', element: <Profile /> },
      { path: '/edit-profile', name: 'Edit Profile', element: <EditProfile /> },
      { path: '/change-password', name: 'Change Password', element: <ChangePassword /> },
      // { path: '/vip-players', name: 'Players', element: <Players /> },
      { path: '/qr-players', name: 'QRPlayers', element: <QRPlayers /> },
      { path: '/staffs', name: 'Staffs', element: <Staffs /> },
      { path: '/reports', name: 'Transaction Reports', element: <TransacReports /> },
      { path: '/staff-reports', name: 'Staff List', element: <KioskStaffList /> },
      { path: '/kiosk-teller-reports', name: 'Kiosk Reports', element: <KioskTellerReports /> },
      { path: '/kiosk-cashier-reports', name: 'Kiosk Reports', element: <KioskCashierReports /> },
      // { path: '/vip-reports', name: 'VIP Reports', element: <VIPReports /> },
      { path: '/qr-reports', name: 'QR Reports', element: <DownlineQrPlayerReports /> },
      { path: '/activity-reports', name: 'Activity Reports', element: <ActivityLogTabs /> },
      { path: '/activitylogs', name: 'Transaction Reports', element: <ActivityLogs /> },
      {
        path: '/downline-per-event-commission',
        name: 'Events Reports',
        element: <DownlinePerEventReport />,
      },

      {
        path: '/vip-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabs />,
      },
      {
        path: '/qr-records',
        name: 'Transaction Reports',
        element: <OperatorReportTabsQR />,
      },
      {
        path: '/manage-commission',
        name: 'Manage Commission',
        element: <OperatorCommissionManagement />,
      },
      { path: '/my-request-details', name: 'My Request Details', element: <MyRequestDetails /> },
      { path: '/request', name: 'Request', element: <Request /> },
      { path: '/my-request', name: 'My Request', element: <MyRequest /> },
      { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/owned-staff', name: 'Owned Staff', element: <OwnedStaffs /> },
      { path: '/request-downline', name: 'My Request Downline', element: <RequestDownline /> },
      { path: '/request-approval', name: 'My Request Approval', element: <RequestApproval /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]

export const defaultRoutes = [
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]
