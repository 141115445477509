import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

const composedEnhancer = applyMiddleware(thunkMiddleware)

const initialState = {
  sidebarShow: false,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, composedEnhancer)
export default store
