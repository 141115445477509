import axios from '../../axios/axiosPlayer'

const getUserProfile = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_PLAYER_API_URL}/api/User/Profile`)
    return response
  }

export default getUserProfile



