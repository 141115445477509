import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PLAYER_API_URL,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token')
    const parsedToken = JSON.parse(token)
    if (parsedToken?.accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${parsedToken?.accessToken}`,
        'Content-Type': 'application/json',
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.setItem('token', JSON.stringify(null))
      window.location.replace('/')
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
